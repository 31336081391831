<template>
  <div id="order-view-services">
    <b-overlay :show="quoteLoading">
      <b-card no-body>
        <b-card-body>
          <div class="d-block d-sm-flex justify-content-between align-items-end">
            <h4>
              {{ $t('order.service_tracking') }}
            </h4>
            <b-button
              v-if="servicesEdition && isQuoteOwner && quote.lines"
              id="order-view-service-update-all-services-to-delivered"
              v-ripple.400
              :block="$store.getters[`app/smAndDown`]"
              variant="outline-primary"
              :disabled="allServicesAreNotDelivered"
              @click.prevent="updateAllServicesToDelivered()"
            >
              <span class="text-nowrap">
                {{ $t('order.update_all_services_to_delivered') }}
              </span>
            </b-button>
          </div>
          <div class="d-flex justify-content-start align-items-end mt-1 note-min-width">
            <span>{{ $t('common.note') }} {{ $t('order.service_tracking_note') }}</span>
          </div>
        </b-card-body>
        <validation-observer ref="order-view-services-observer">
          <b-table
            ref="order-view-services"
            :class="['position-relative', { 'table-dropdown-action': servicesEdition }]"
            thead-class="text-nowrap"
            :stacked="$store.getters[`app/mdAndDown`]"
            :items="quote.lines"
            :fields="linesDocumentFields"
            :empty-text="$t('common.no_record_found')"
          >
            <!-- LOADING -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- CELL -->
            <!-- date -->
            <template #cell(date)="data">
              <b-form-group class="mt-md-1 input-group">
                <flat-pickr
                  v-if="servicesEdition && data.item.lineClass !== 'HangarQuoteLine'"
                  :id="`order-view-service-${data.item.id}-date`"
                  v-model="data.item.date"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'F j, Y H:i',
                    dateFormat: 'Y-m-dTH:i',
                    enableTime: true,
                    time_24hr: true,
                    locale: $i18n.locale,
                  }"
                  @on-close="updateLineDate(data.item)"
                />
              </b-form-group>
              <span v-if="!servicesEdition && data.item.date && data.item.lineClass !== 'HangarQuoteLine'" class="text-nowrap">
                {{
                  new Date(data.item.date).toLocaleDateString($i18n.locale, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  })
                }}
              </span>
            </template>
            <!-- status -->
            <template #cell(status)="data">
              <b-form-group v-if="servicesEdition && isQuoteOwner" class="mt-md-1 input-group">
                <enum-select
                  :id="`order-view-service-${data.item.id}-status`"
                  v-model="data.item.status"
                  :placeholder="$t('common.status')"
                  :clearable="false"
                  :searchable="false"
                  enum-class="quoteLineStatus"
                  required
                  @input="updateLineStatus(data.item)"
                >
                  <template #option="{ value }">
                    <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                      {{ value }}
                    </b-badge>
                  </template>
                  <template #selected-option="{ value }">
                    <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                      {{ value }}
                    </b-badge>
                  </template>
                </enum-select>
              </b-form-group>
              <b-badge v-else-if="!servicesEdition || !isQuoteOwner" pill :variant="`${resolveSupplyLineStatusVariant(data.item.status)}`">
                {{ data.item.status }}
              </b-badge>
            </template>
          </b-table>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import {
  patchQuoteQuoteLinesDatesRequest,
  patchQuoteQuoteLinesStatusRequest,
} from '@/request/globalApi/requests/quoteRequests'

import { mapActions, mapState } from 'vuex'

import { mapFields } from 'vuex-map-fields'

export default {
  name: 'OrderViewServices',

  mixins: [UIHelper],

  props: {
    servicesEdition: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      quote: { lines: [] },
      allServicesAreNotDelivered: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner', 'quoteLoading']),
    ...mapFields('quote', ['quoteLoading']),

    linesDocumentFields() {
      const linesDocumentFields = [
        {
          key: 'name',
          label: this.$t('common.name'),
        },
      ]
      if (this.quoteSelected.quote.state === 'awaiting_for_services_completed' || this.quoteSelected.quote.state === 'delivered') {
        linesDocumentFields.push(
          {
            key: 'date',
            label: this.$t('common.date'),
          },
          {
            key: 'status',
            label: this.$t('common.status'),
            thStyle: 'width: 18em',
            tdStyle: 'width: 18em',
          },
        )
      }
      return linesDocumentFields
    },
  },

  watch: {
    'quoteSelected.quote': {
      handler(value) {
        this.quote = this._cloneDeep(value)
      },
      deep: true,
    },
    'quote.lines': {
      handler(value) {
        this.allServicesAreNotDelivered = !value.some(line => line.status !== 'delivered')
      },
      deep: true,
    },
  },

  mounted() {
    this.quote = this._cloneDeep(this.quoteSelected.quote)
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    canBeModifiedDeliveryDate(data) {
      return data.item.status !== 'in_progress' && data.item.status !== 'delivered'
    },

    updateLineDate(line) {
      if (line.date) {
        this.quoteLoading = true
        patchQuoteQuoteLinesDatesRequest(
          this.quoteSelected.quote.id,
          { quoteLinesDates: [{ quoteLineId: line.id, date: line.date }] },
          this.$store.getters['quote/quoteTitleAndId'],
        )
          .then(() => {
            this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }
    },

    updateLineStatus(line) {
      this.quoteLoading = true
      patchQuoteQuoteLinesStatusRequest(
        this.quoteSelected.quote.id,
        { quoteLinesStatus: [{ quoteLineId: line.id, status: line.status }] },
        this.$store.getters['quote/quoteTitleAndId'],
      )
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },

    updateAllServicesToDelivered() {
      this.quoteLoading = true
      const quoteLinesStatus = []
      this.quote.lines.forEach(line => {
        quoteLinesStatus.push({ quoteLineId: line.id, status: 'delivered' })
      })
      patchQuoteQuoteLinesStatusRequest(this.quoteSelected.quote.id, { quoteLinesStatus }, this.$store.getters['quote/quoteTitleAndId'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-dropdown-action {
  min-height: 250px;
}
.input-group {
  min-width: 180px;
}
.note-min-width {
  min-width: 300px;
}
</style>
